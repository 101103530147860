// styling
import styles from "./style.module.scss";

// components
import LazyImage from "@components/LazyImage";

// assets
import logo from "@assets/white-logo-transparent.png";
import img1 from "@assets/post/1.png";
import img2 from "@assets/home/cover.png";

const PrivacyPolicyContent = () => {
  return (
    <div className="post-section">
      <div className={styles.header}>
        <div className={styles.header_author}>
          <LazyImage className={styles.img_title} src={logo} alt="Probal" />
        </div>
      </div>
      <article>
        <h3 className={styles.title}>Privacy Policy</h3>
        <p>
          Probal/Probal Labs ("us", "we", or "our") operates the website
          probal.io (the "Service"). This page informs you of our policies
          regarding the collection, use, and disclosure of personal data when
          you use our Service and the choices you have associated with that
          data.
        </p>
        <br />
        <p>
          Information Collection and Use We collect several different types of
          information for various purposes to provide and improve our Service to
          you.
        </p>
        <div className={styles.grid}>
          <div className={`${styles.media} border-10`}>
            <LazyImage
              className={styles.media_img}
              src={img2}
              alt="Features of New Projects"
            />
          </div>
          <div className="d-flex flex-column g-20">
            <p>
              Types of Data Collected Personal Data While using our Service, we
              may ask you to provide us with certain personally identifiable
              information that can be used to contact or identify you ("Personal
              Data"). Personally identifiable information may include, but is
              not limited to:
            </p>
            <ul className="post_list d-flex flex-column g-10 text-light">
              <li className="d-flex g-10">
                <i className={`${styles.icon} icon-circle-check text-accent`} />
                Your digital wallet's public key
              </li>
              <li className="d-flex g-10">
                <i className={`${styles.icon} icon-circle-check text-accent`} />
                Cookies and Usage Data
              </li>
            </ul>
          </div>
        </div>
        <q
          className={`${styles.quote} ${styles.spacer} h4 text-gradient border-10`}
        >
          Create a world of independent creators fully supported by their fans,
          and everyday people.
        </q>
        <p>
          We may also collect optional information such as your name, images,
          location and email address. Additionally, we collect your public
          key/address and all your activity on the platform (if you are a
          supporter - which NFT projects you minted, if you are an artist -
          which projects are under your name and their details).
        </p>
        <div
          className={`${styles.media} ${styles.secondary} ${styles.spacer} border-10`}
        >
          <LazyImage className={styles.media_img} src={img1} alt="Probal" />
        </div>
        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Usage Data</h4>
          <div className="d-flex flex-column g-15">
            <p>
              We may also collect information on how the Service is accessed and
              used ("Usage Data"). This Usage Data may include information such
              as your computer's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </p>
          </div>
        </div>
        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Tracking & Cookies Data</h4>
          <div className="d-flex flex-column g-15">
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.
            </p>
            <br />
            <p>Examples of Cookies we use:</p>
            <ol className="post_list d-flex flex-column g-5">
              <li className="d-flex g-10">
                <span className="text-accent">1.</span>
                Session Cookies: We use Session Cookies to operate our Service.
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">2.</span>
                Preference Cookies: We use Preference Cookies to remember your
                preferences and various settings.
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">3.</span>
                Security Cookies: We use Security Cookies for security purposes.
              </li>
            </ol>
          </div>
        </div>
        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Use of Data</h4>
          <div className="d-flex flex-column g-15">
            <p>Probal Labs uses the collected data for various purposes:</p>
            <ol className="post_list d-flex flex-column g-5">
              <li className="d-flex g-10">
                <span className="text-accent">1.</span>
                To provide and maintain the Service
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">2.</span>
                To notify you about changes to our Service
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">3.</span>
                To allow you to participate in interactive features of our
                Service when you choose to do so
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">4.</span>
                To provide customer care and support
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">5.</span>
                To provide analysis or valuable information so that we can
                improve the Service
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">6.</span>
                To monitor the usage of the Service
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">7.</span>
                To detect, prevent and address technical issues
              </li>
            </ol>
          </div>
        </div>
      </article>
    </div>
  );
};

export default PrivacyPolicyContent;
