// components
import Title from "@components/Title";
import PrivacyPolicyContent from "@layout/privacy-policy";

const PrivacyPolicy = () => {
  return (
    <>
      <Title title="Privacy Policy" />
      <main>
        <div className="section">
          <div className="container">
            <PrivacyPolicyContent />
          </div>
        </div>
      </main>
    </>
  );
};

export default PrivacyPolicy;
