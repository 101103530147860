// styling
import styles from "./style.module.scss";

// components
import LazyImage from "@components/LazyImage";
import Lottie from "lottie-react";

// assets
import logo from "@assets/white-logo-transparent.png";
import invest from "@assets/lottie/invest.json";
import robot from "@assets/lottie/robot.json";

const TermsOfServiceContent = () => {
  return (
    <div className="post-section">
      <div className={styles.header}>
        <div className={styles.header_author}>
          <LazyImage className={styles.img_title} src={logo} alt="Probal" />
        </div>
      </div>
      <article>
        <h3 className={styles.title}>Terms Of Service</h3>
        <p>
          By accessing or using Probal, you agree to be bound by these Terms of
          Service.
        </p>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Use of Probal</h4>
          <div className="d-flex flex-column g-15">
            <p>
              Probal is a web3 crowdfunding platform that allows creators to
              fund their projects through contributions from supporters. You may
              use Probal as a supporter or as an artist looking for funding.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Eligibility</h4>
          <div className="d-flex flex-column g-15">
            <p>
              To use Probal, you must be at least 18 years old and have the
              legal capacity to enter into a binding contract. By using Probal,
              you represent and warrant that you meet these eligibility
              requirements.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>User Accounts</h4>
          <div className="d-flex flex-column g-15">
            <p>
              To use Probal, you must create a user account. You agree to
              provide accurate, complete, and current information when creating
              your account and to update your information as necessary.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>User Conduct</h4>
          <div className="d-flex flex-column g-15">
            <p>
              You agree to use Probal only for lawful purposes and in compliance
              with these Terms of Service. You will not:
            </p>
            <ol className="post_list d-flex flex-column g-5">
              <li className="d-flex g-10">
                <span className="text-accent">1.</span>
                Use Probal for any illegal or unauthorized purpose
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">2.</span>
                Post or transmit any material that is unlawful, defamatory,
                obscene, or offensive
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">3.</span>Interfere with the
                operation of Probal or the servers or networks used to make
                Probal available
              </li>
              <li className="d-flex g-10">
                <span className="text-accent">4.</span>Attempt to gain
                unauthorized access to any portion of Probal or any other
                accounts, computer systems, or networks connected to Probal
              </li>
            </ol>
          </div>
        </div>
        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Contributions</h4>
          <div className="d-flex flex-column g-15">
            <p>
              If you are a supporter or a fan, you may buy music or movie NFTs
              on Probal Labs. You agree that your contribution by purchasing
              NFTs is risky and could lose all of your contribution. You also
              agree that Probal Labs has no responsibility for the success or
              failure of any project.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Projects</h4>
          <div className="d-flex flex-column g-15">
            <p>
              If you are an artist, you may create projects on Probal Labs to
              seek funding. You agree to provide accurate, complete, and current
              information about your projects, including your funding goals, and
              to update this information as necessary. You also agree to use any
              funding you receive through Probal solely for the purposes of your
              project.
            </p>
          </div>
        </div>

        <div className={`${styles.media} ${styles.secondary} border-10`}>
          <Lottie className="lottie" animationData={invest} />
        </div>
        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Fees</h4>
          <div className="d-flex flex-column g-15">
            <p>
              Probal Labs charges a fee for its services. You agree to pay
              PProbal Labs the applicable fees for your use of the service.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Intellectual Property</h4>
          <div className="d-flex flex-column g-15">
            <p>
              Probal Labs and its content are protected by copyright, trademark,
              and other intellectual property laws. You agree not to copy,
              distribute, or otherwise use any portion of Probal Labs or its
              content without Probal Labs's prior written consent.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Disclaimer of Warranties</h4>
          <div className="d-flex flex-column g-15">
            <p>
              Probal is provided "as is" and "as available." Probal makes no
              representations or warranties of any kind, express or implied, as
              to the operation of Probal or the information, content, materials,
              or products included on Probal. You use Probal at your own risk.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Limitation of Liability</h4>
          <div className="d-flex flex-column g-15">
            <p>
              In no event shall Probal Labs be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of or in
              connection with the use of Probal or its content. Probal Labs's
              total liability to you for all claims arising out of or in
              connection with the use of Probal Labs or its content shall not
              exceed the amount of fees paid by you to Probal Labs.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Indemnification</h4>
          <div className="d-flex flex-column g-15">
            <p>
              You agree to indemnify and hold harmless Probal Labs and its
              affiliates, officers, directors, agents, and employees from any
              claim or demand, including reasonable attorneys' fees, made by any
              third party due to or arising out of your use of Probal Labs, your
              violation of these Terms of Service, or your violation of any
              rights of another person or entity.
            </p>
          </div>
        </div>

        <div className={`${styles.media} ${styles.secondary} border-10`}>
          <Lottie className="lottie" animationData={robot} />
        </div>
        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Termination</h4>
          <div className="d-flex flex-column g-15">
            <p>
              We may terminate or suspend your access to Probal Labs
              immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if you breach the Terms.
              <br />
              All provisions of the Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers, indemnity,
              and limitations of liability.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Governing Law</h4>
          <div className="d-flex flex-column g-15">
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of the State of California, without regard to its conflict of
              law provisions.
              <br />
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect.
              <br />
              These Terms constitute the entire agreement between us regarding
              our Service, and supersede and replace any prior agreements we
              might have had between us regarding the Service.
            </p>
          </div>
        </div>

        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Changes</h4>
          <div className="d-flex flex-column g-15">
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material, we will try to
              provide at least 30 days' notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
              <br />
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, please stop using the Service.
            </p>
          </div>
        </div>
        <div className={styles.spacer}>
          <h4 className={styles.subtitle}>Contact Us</h4>
          <div className="d-flex flex-column g-15">
            <p>
              If you have any questions about these Terms, please contact us at
              <a href="mailto:wisaac@probal.io" className="text-accent">
                {" "}
                wisaac@probal.io
              </a>
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default TermsOfServiceContent;
