// components
import Title from "@components/Title";
import Logo from "@components/Logo";

const LaunchingSoon = () => {
  return (
    <>
      <Title title="Launching Soon" />
      <div style={{ height: "40vh", textAlign: "center", marginTop: "10%" }}>
        <h2>Launching Soon...</h2>
        <div style={{ marginTop: "20px" }}>
          <Logo />
        </div>
      </div>
    </>
  );
};

export default LaunchingSoon;
