// components
import { NavLink } from "react-router-dom";

// assets
import logo from "@assets/white-logo-transparent.png";

const Logo = () => {
  return (
    <NavLink className="logo d-inline-flex align-items-center" to="/">
      <img className="logo_img" src={logo} alt="Probal" />
    </NavLink>
  );
};

export default Logo;
